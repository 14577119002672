import * as React from 'react'
import { LocationModels } from '@getgreenline/locations'
import { BlazeButton } from '../../../../../components/CustomButtons/BlazeButton'
import { observer, inject } from 'mobx-react'
import { CreateProduct, CreateChildProduct } from '../ProductStore'
import { CurrentCompanyStore } from '../../../../../stores/CurrentCompanyStore'
import { MultiLocationCannabinoid } from './MultiLocationCannabinoid'
import { observable } from 'mobx'
import { CustomDivider } from '../../../../../components/CustomDivider'
import { CurrentUserStore } from '../../../../../stores/CurrentUserStore'
import { PermissionNames } from '@getgreenline/homi-shared'
import { GlobalCannabinoidProfileModal } from '../GlobalCannabinoidProfileModal'
import { CBD_THC_UNIT } from './Cannabinoids'

interface InjectedProps {
  currentCompanyStore?: CurrentCompanyStore
  currentUserStore?: CurrentUserStore
}

interface Props extends InjectedProps {
  product: CreateProduct | CreateChildProduct
  disabled: boolean
}

interface State {
  showCannabinoidProfileModal: boolean
}
@inject('currentCompanyStore', 'currentUserStore')
@observer
export class MultiLocationCannabinoids extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showCannabinoidProfileModal: false,
    }
  }
  LIMIT = 250

  @observable
  mappedGlobalLocations = new Map<number, LocationModels.IGlobalLocationContract>()

  componentDidMount = async () => {
    if (!this.props.currentCompanyStore!.locations) {
      this.props.currentCompanyStore!.getLocations()
    }

    ;(this.props.currentCompanyStore!.globalLocations || []).forEach((location) => {
      this.mappedGlobalLocations.set(location.id, location)
    })
  }

  get orderedPermittedLocations() {
    return this.userPermittedLocations.sort((a, b) => a.name.localeCompare(b.name))
  }

  get allLocationsUsed() {
    const locationCnbs = this.props.product.cannabinoidMetaData.cannabinoids.filter(
      (cnb) => cnb.isLocationCannabinoid,
    )
    const allSelectedLocationIds = locationCnbs.reduce((prev: number[], cnb) => {
      return [...prev, ...cnb.locationIds]
    }, [])

    const permittedLocationSet = new Set(this.userPermittedLocations.map((loc) => loc.id))

    for (const selectedLoc of allSelectedLocationIds) {
      permittedLocationSet.delete(selectedLoc)
    }

    return permittedLocationSet.size === 0
  }

  get userPermittedLocations() {
    const { currentCompanyStore, currentUserStore } = this.props

    if (!currentCompanyStore?.locations || !currentUserStore) {
      return []
    }

    const locations = currentCompanyStore.locations
    return locations.filter((location) =>
      currentUserStore.hasPermissionAtEntity(PermissionNames.CAN_EDIT_PRODUCTS, location.id),
    )
  }

  get locationCannabinoids() {
    const { cannabinoids } = this.props.product.cannabinoidMetaData
    return cannabinoids.filter((cbn) => cbn.isLocationCannabinoid)
  }

  render() {
    const { product } = this.props
    const { cannabinoidMetaData } = product

    return (
      <div>
        <CustomDivider title='Multi-location THC/CBD' className='mt-0' />

        <div style={{ display: 'flex', gap: 8 }}>
          <BlazeButton
            titleProps={{
              title: this.allLocationsUsed ? 'All locations selected' : 'Add cannabinoid profile',
            }}
            buttonProps={{
              icon: 'plus',
              className: 'mb-3',
              disabled: this.props.disabled || this.allLocationsUsed,
              onClick: () => {
                cannabinoidMetaData.addCannabinoid(
                  product.metaData.unit,
                  product.metaData.showMaxMin,
                )
              },
            }}
          />
          {this.props.currentCompanyStore!.canUseGlobalCatalog && product.globalProductId && (
            <BlazeButton
              titleProps={{
                title: 'Select a cannabinoid profile',
              }}
              buttonProps={{
                icon: 'plus',
                className: 'mb-3',
                disabled: this.props.disabled || this.allLocationsUsed,
                onClick: () => {
                  this.setState({ showCannabinoidProfileModal: true })
                },
              }}
            />
          )}
        </div>

        {cannabinoidMetaData.cannabinoids.map((cnb, index) =>
          cnb.isLocationCannabinoid !== false ? (
            <MultiLocationCannabinoid
              key={index}
              index={index}
              disabled={this.props.disabled}
              product={this.props.product}
              cannabinoid={cnb}
              orderedPermittedLocations={this.orderedPermittedLocations}
              mappedGlobalLocations={this.mappedGlobalLocations}
            />
          ) : null,
        )}
        <GlobalCannabinoidProfileModal
          visible={this.state.showCannabinoidProfileModal}
          globalProductId={product.globalProductId}
          onSelectProfile={(profile) => {
            cannabinoidMetaData.addCannabinoidMetaData({
              unit: profile.potency.unit as CBD_THC_UNIT,
              maxTHC: profile.potency.maxThc,
              maxCBD: profile.potency.maxCbd,
              minTHC: profile.potency.minThc,
              minCBD: profile.potency.minCbd,
              showMaxMin: true,
            })
          }}
          onClose={() => this.setState({ showCannabinoidProfileModal: false })}
        />
      </div>
    )
  }
}
