import dotenv from 'dotenv'

dotenv.config()

export default {
  ga: {
    measurementId: process.env.GA_MEASUREMENT_ID || 'G-DCX95VJY0K',
  },
  aws: {
    cognito: {
      userPoolId: `${process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID}`,
      clientId: `${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}`,
    },
    location: {
      apiKey: `${process.env.REACT_APP_AWS_LOCATION_API_KEY}`,
      mapName: `${process.env.REACT_APP_AWS_LOCATION_MAP_NAME}`,
      region: `${process.env.REACT_APP_AWS_LOCATION_API_REGION}`,
    },
  },
}
