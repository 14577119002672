import * as React from 'react'
import { Helmet } from 'react-helmet'
import { browserHistory } from 'react-router'
import { inject, observer, Provider } from 'mobx-react'
import { THEME_COLORS } from '@getgreenline/shared'
import * as Sentry from '@sentry/react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { POSStore } from '../../stores/POSStore'
import { ShiftLogin } from './ShiftLogin'
import { POSNavbar } from './POSNavbar'
import { LoadingAnimation } from '../../components/LoadingAnimation'
import { Routes } from '../../constants/Routes'
import { IDevice, ILocation, API } from '@getgreenline/homi-shared'
import { Track } from '../../Track'
import { setRootAuthToken } from '../../utilities/environment'
import { FeatureToggle } from '../../constants/FeatureToggles'
import { WorldpayStore } from '../../stores/WorldpayStore'
import { WebSocketStore } from '../../stores/WebsocketStore'
import './index.scss'
import { ShiftApi, ShiftModels } from '@getgreenline/shifts'
import { PaymentServiceStore } from '../../stores/PaymentServiceStore/PaymentServiceStore'

interface Props {
  currentCompanyStore?: CurrentCompanyStore
  location: any
}

interface State {
  device?: IDevice
  shift?: ShiftModels.IShift | null
  location?: ILocation
  posStore?: POSStore
  paymentServiceStore?: PaymentServiceStore
  worldpayStore?: WorldpayStore
  webSocketStore?: WebSocketStore
}

@inject('currentCompanyStore')
@observer
export class POS extends React.Component<React.PropsWithChildren<Props>, State> {
  constructor(props: React.PropsWithChildren<Props>) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    // POS uses different auth token from the dashboard
    const deviceAuthToken = window.localStorage.getItem('deviceAuthToken')

    if (!deviceAuthToken) {
      browserHistory.push(Routes.LOGIN)
      return
    }

    setRootAuthToken(deviceAuthToken)

    // Get the device matching the auth token
    const device = await API.getCurrentDevice()
      .then((device) => {
        this.setState({ device })
        return device
      })
      .catch(() => {
        // If it doesn't match, kick out to the login screen
        browserHistory.push(Routes.LOGIN)
      })

    // To make subsequent type checking happy
    if (!device) {
      browserHistory.push(Routes.LOGIN)
      return
    }

    const { currentCompanyStore } = this.props
    if (!currentCompanyStore) return

    // Load company into companyStore
    const company = await currentCompanyStore.getById(device.companyId).then((company) => {
      currentCompanyStore?.getLocations()
      return company
    })

    const location = await API.getLocationById(company.id, device.locationId).then((location) => {
      this.setState({ location })
      return location
    })

    const shift = await ShiftApi.getCurrentShift(company.id, device.id).then((shift) => {
      this.setState({ shift })
      return shift
    })

    const { productStore } = currentCompanyStore
    if (!productStore) return

    // Assign company on Intercom
    Track.setGroup(company.id, {
      id: company.id,
      name: company.name,
      province: company.province,
      createDate: company.createDate,
    })

    const canUseWebSocket = await currentCompanyStore.isFeatureEnabled(
      FeatureToggle.CAN_USE_WEBSOCKET,
    )

    const webSocketStore = new WebSocketStore({
      tenantId: company.id,
      retailerId: location.id,
      authorization: deviceAuthToken,
      canUseWebSocket,
      deviceId: device.id,
    })

    this.setState({ webSocketStore })

    const posStore = new POSStore(
      company,
      location,
      device,
      shift,
      productStore,
      currentCompanyStore,
      webSocketStore,
    )
    await posStore.getEmployeePermissions()

    const paymentServiceStore = new PaymentServiceStore(company.id, location.id, device.id)
    this.setState({ paymentServiceStore })

    Sentry.setUser({
      username: device.deviceId,
      device,
      shift: shift?.currentUserName || shift?.currentUserId,
    })

    const useSearchBar = await currentCompanyStore.isFeatureEnabled(
      FeatureToggle.NEW_PRODUCT_SEARCH,
    )
    posStore.setUseSearchBar(useSearchBar)

    this.setState({ posStore })

    currentCompanyStore.getGiftCardContracts()
    currentCompanyStore.refreshBirchmountConfigs(company.id, location.id)

    await currentCompanyStore.getWorldpayStatus()

    if (currentCompanyStore.canUseWorldpay) {
      const worldpayStore = new WorldpayStore(company.id, location.id)

      worldpayStore.setCachedConnectionsAndSelectedTerminal()

      this.setState({ worldpayStore })
    }

    // check if feature flags are enabled
    const featureFlags = [
      currentCompanyStore.getCannabinoidPerBatchStatus(),
      currentCompanyStore.getProductDetailsPackageDateStatus(),
      currentCompanyStore.getProductDetailsTerpenesAndCannbinoidsStatus(),
      currentCompanyStore.getCanDisplayLoyaltyInfoOnReceipt(),
      currentCompanyStore.getCanUseCustomReceipt(),
      currentCompanyStore.getCanUseCustomerSearchV2(),
      currentCompanyStore.getCanDisplayOnlineOrdersReceipts(),
      currentCompanyStore.getCanUsePlatformCustomersSearch(),
      currentCompanyStore.getCanUseAutoLoyaltyDiscount(),
      currentCompanyStore.getCanUseSalesChannel(),
      currentCompanyStore.getCanUseGlobalCatalogStatus(),
    ]

    await Promise.all(featureFlags)

    const { canUseExternalLoyalty, canUseSalesChannel } = currentCompanyStore
    const canAccessLoyalty = !!currentCompanyStore.company?.permissions.canAccessLoyalty

    const discounts = await productStore.getPOSDiscounts({
      searchFilter: {
        locationIds: [posStore.location.id],
      },
      permissions: {
        canUseExternalLoyalty,
        canAccessLoyalty,
      },
      canUseSalesChannel,
    })

    const categories = await productStore.getCategories()

    posStore.currentPayment.setDiscounts(discounts)
    posStore.currentPayment.setCategories(categories)

    await currentCompanyStore.setCustomReceiptSetting(location.id)
    currentCompanyStore.getExternalLoyaltyProvider(currentCompanyStore.company!.id)
  }

  componentWillUnmount() {
    this.state.webSocketStore?.webSocket?.close()
    this.state.paymentServiceStore?.unsubscribeAll()
  }

  render() {
    const company = this.props.currentCompanyStore!.company
    const location = this.state.location
    const device = this.state.device
    const shift = this.state.shift

    const centerStyle: React.CSSProperties = {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      color: THEME_COLORS.MONO.monodd,
    }

    if (!device) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Initializing device...</div>
          </div>
        </div>
      )
    } else if (!company) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading company...</div>
          </div>
        </div>
      )
    } else if (shift === undefined) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading shift...</div>
          </div>
        </div>
      )
    } else if (!location) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading location...</div>
          </div>
        </div>
      )
    } else if (!this.state.posStore) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading POS...</div>
          </div>
        </div>
      )
    } else if (this.state.posStore) {
      const posStore = this.state.posStore!
      const worldpayStore = this.state.worldpayStore
      const webSocketStore = this.state.webSocketStore
      if (shift) {
        return (
          <Provider
            posStore={posStore}
            paymentServiceStore={this.state.paymentServiceStore}
            worldpayStore={worldpayStore}
            webSocketStore={webSocketStore}
          >
            <>
              <Helmet>
                <title>POS</title>
                <meta name='page-loaded' content='true' />
              </Helmet>
              <div style={{ height: '100vh' }}>
                <div style={{ height: '56px' }}>
                  <POSNavbar />
                </div>
                <div style={{ width: `calc(100vw)`, height: 'calc(100vh - 56px)' }}>
                  {this.props.children}
                </div>
              </div>
            </>
          </Provider>
        )
      } else {
        return (
          <Provider
            posStore={posStore}
            worldpayStore={worldpayStore}
            webSocketStore={webSocketStore}
          >
            <>
              <Helmet>
                <title>POS | Shift start</title>
                <meta name='page-loaded' content='true' />
              </Helmet>
              <ShiftLogin location={this.props.location} />
            </>
          </Provider>
        )
      }
    } else {
      return (
        <div style={centerStyle}>
          <div>
            <div>Error</div>
          </div>
        </div>
      )
    }
  }
}
