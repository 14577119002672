import { ILabResult } from '@getgreenline/global-products'
import { Button, Col, Row, Typography } from 'antd-v4'
import dayjs from 'dayjs'
import React from 'react'

interface IGlobalCannabinoidProfileCardProps {
  profile: ILabResult
  onSelectProfile: (profile: ILabResult) => void
}
export const GlobalCannabinoidProfileCard: React.FC<IGlobalCannabinoidProfileCardProps> = ({
  profile,
  onSelectProfile,
}) => {
  return (
    <React.Fragment>
      <Typography style={{ fontWeight: 'bold' }}>
        {dayjs(profile.date).format('YYYY-MM-DD')}
      </Typography>
      <Row style={{ alignItems: 'flex-start', display: 'flex' }}>
        <Col style={{ flex: 1 }}>
          <Typography>Province: {profile.region}</Typography>
          <Typography>
            THC Min/max: {`${profile?.potency?.minThc ?? 0}-${profile?.potency?.maxThc ?? 0}`}
          </Typography>
          <Typography>
            CBD Min/max: {`${profile?.potency?.minCbd ?? 0}-${profile?.potency?.maxCbd ?? 0}`}
          </Typography>
          <Typography>Terpenes: {profile?.terpenes.join(', ')}</Typography>
        </Col>
        <Col>
          <Button onClick={() => onSelectProfile(profile)}>
            Select <span className='fa fa-caret-right ml-2' />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}
