import { axios } from '@getgreenline/homi-shared'
import React, { useEffect, useState } from 'react'
import { Divider, Modal } from 'antd-v4'
import { GlobalCannabinoidProfileCard } from './GlobalCannabinoidProfileCard'
import { IGlobalProduct, ILabResult } from '@getgreenline/global-products'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { CurrentCompanyStore } from '../../../../../stores/CurrentCompanyStore'
import { Skeleton, message } from 'antd'

interface IGlobalCannabinoidProfileModalProps {
  visible: boolean
  globalProductId: string | null
  onClose: () => void
  currentCompanyStore?: CurrentCompanyStore
  onSelectProfile: (profile: ILabResult) => void
}

const _GlobalCannabinoidProfileModal: React.FC<IGlobalCannabinoidProfileModalProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [globalProduct, setGlobalProduct] = useState<IGlobalProduct | undefined>(undefined)
  const getGlobalProductContract = async () => {
    if (props.globalProductId === null) {
      setGlobalProduct(undefined)
      return
    }
    setIsLoading(true)
    try {
      const response = await props.currentCompanyStore!.getGlobalProducts({
        limit: 5,
        offset: 0,
        query: props.globalProductId,
      })
      if (response && response.products) {
        const productsFound = response.products
        const productFound = productsFound.find(
          (product: IGlobalProduct) => product.barcode === props.globalProductId,
        )
        setGlobalProduct(productFound)
      } else {
        setGlobalProduct(undefined)
      }
    } catch (error) {
      message.error('There was a problem searching for global products, please try again')
      setGlobalProduct(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.visible) {
      getGlobalProductContract()
    } else {
      setGlobalProduct(undefined)
    }
  }, [props.visible])

  const profiles = globalProduct?.labResults ?? []
  return (
    <Modal
      footer={null}
      onCancel={props.onClose}
      visible={props.visible}
      title='Cannabinoid Profiles'
    >
      <div>
        {isLoading ? (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        ) : (
          <div style={{ maxHeight: '400px', overflowX: 'auto' }}>
            {profiles?.map((profile: any, index: number) => (
              <React.Fragment key={profile.id}>
                <GlobalCannabinoidProfileCard
                  onSelectProfile={() => {
                    props.onSelectProfile(profile)
                    props.onClose()
                  }}
                  profile={profile}
                />
                {index < profiles.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

export const GlobalCannabinoidProfileModal = inject('currentCompanyStore')(
  observer(_GlobalCannabinoidProfileModal),
)
